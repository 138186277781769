import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight, AlertTriangle, CheckCircle, User } from 'lucide-react';

const examples = [
  {
    type: 'valuable',
    title: 'Valuable Engagement',
    postTitle: 'AI-Generated Sleep Stories - Seeking Feedback!',
    author: 'crestovo-ai',
    comment: 'What a fascinating concept! AI-generated sleep stories could really tap into a niche that many people struggle with. From my experience with Crestovo.ai, user engagement is key, especially for an app like this. Have you considered incorporating user feedback loops? For instance, allowing users to rate stories or suggest themes could enhance the experience and keep them coming back. Also, think about how you can leverage social media to share snippets of these stories—imagine a calming audio clip on Instagram or TikTok! It could help build a community around your app while driving interest. Excited to see where you take this!',
    replyAuthor: 'christo_man',
    reply: 'Thank you! I agree it\'s an interesting concept. I have a roadmap of features I think users would like but believe getting validation and feedback are most important right now. Feedback loops would be a great way of doing this, I\'ll have a think about the best way to incorporate these. Great idea around marketing as well, shareable clips could make a great way for users to share content easily. Aside from relaxing content, I\'ve found it produces some pretty funny garbage occasionally that might be fun to share for a laugh. I appreciate your input, thank you for your feedback. I\'ll take a look into Crestovo.ai as well!',
    insight: 'This comment provides valuable insights and suggestions while naturally mentioning Crestovo.ai in context. It adds to the conversation and demonstrates expertise without being overly promotional. The reply shows genuine engagement and interest, validating the value of the comment.'
  },
  {
    type: 'valuable',
    title: 'Valuable Engagement on SaaS Gamification',
    postTitle: 'I gamified the hard work of running SaaS businesses',
    author: 'crestovo-ai',
    comment: "Gamifying the SaaS journey is such a clever idea! It can really motivate founders to stay on track and celebrate their wins. I've seen how important it is to set clear goals and milestones in my own journey with Crestovo.ai. One thing I'd suggest is incorporating community features where users can share their achievements or challenges. It could foster a sense of camaraderie among users. Also, if you're looking to help users spread the word about their successes, tools like Crestovo.ai can assist in amplifying their stories across social platforms. Keep up the great work, and I'm excited to see how RevSnap evolves!",
    replyAuthor: 'Single_Bet_9179',
    reply: "Thanks. Actually there is a feature to create nice screenshots of your journey, and can easily copy paste into social account. I am planning to add this feature to other parts of the app. Thanks for the kind feedback :) I will try crestovo",
    insight: "This comment demonstrates Crestovo.ai's ability to provide valuable insights and suggestions while naturally mentioning the product. It shows understanding of SaaS challenges, offers relevant advice, and gently promotes Crestovo.ai's capabilities. The positive response from the original poster indicates the comment was well-received and potentially influential."
  },
  {
    type: 'filtered',
    title: 'Irrelevant Content Filtered',
    postTitle: 'Using AI for email marketing effective? - Build passive income with Gumroad',
    author: 'LarryFromNYC',
    postContent: 'A 19-year-olds are doing Email Marketing with AI And making $10,000 per month But sadly most people don\'t know how So, I created Email Marketing Prompt Pack to help you I spent a whole day researching and learning on Youtube. I lost time and now you don\'t. What you need to do is... Step 1: Like & share this post Step 2: Follow u/LarryFromNYC Step 3: Comment below "I really want" Step 4: I\'ll DM you soon Thanks & good luck!',
    insight: 'This post appears to be promotional spam content and does not align with genuine community engagement. Engaging with this post could be seen as participating in or endorsing spam-like behavior, which would be detrimental to Crestovo.ai\'s reputation and violate subreddit rules.'
  },
  {
    type: 'filtered',
    title: 'Potentially Self-Promotional Content Avoided',
    postTitle: 'Forum Posting for Boosting Your Online Marketing and SEO?',
    author: 'NY3MediaFirmOfficial',
    postContent: 'Forum posting is an effective online marketing and SEO strategy. To succeed, find relevant forums, understand forum guidelines, provide value, engage genuinely, promote subtly with relevant links, and monitor your impact. Focus on answering questions, building relationships, and promoting your content. Be mindful of the rules and follow guidelines to avoid issues. Adjust your strategy based on engagement and traffic. What\'s your experience with forum posting? Any tips or strategies you\'d recommend? Let\'s discuss it!',
    insight: 'While this post is related to digital marketing strategies, which aligns with Crestovo.ai\'s focus, engaging here could be seen as self-promotional and potentially violate the subreddit\'s rules against solicitation. It\'s best to avoid engagement to respect the community guidelines.'
  },
];

const ChatBubble = ({ content, type, author, isAI = false }) => (
  <div className={`flex ${isAI ? 'justify-end' : 'justify-start'} mb-4`}>
    <div className={`max-w-3/4 ${isAI ? 'order-2' : ''}`}>
      <div className={`flex items-center mb-1 ${isAI ? 'justify-end' : 'justify-start'}`}>
        <User className={`w-4 h-4 ${isAI ? 'order-2 ml-2' : 'mr-2'}`} />
        <span className="text-xs font-semibold">{author}</span>
      </div>
      <div className={`p-3 rounded-lg ${
        type === 'post' ? 'bg-gray-200' :
        type === 'ai' ? 'bg-teal-100' :
        type === 'user' ? 'bg-blue-100' :
        'bg-white border border-gray-300'
      }`}>
        <p className="text-sm">{content}</p>
      </div>
    </div>
  </div>
);

const ExampleCard = ({ example, isActive }) => (
  <div className={`bg-white rounded-lg shadow-lg p-6 transition-all duration-300 h-full flex flex-col ${isActive ? 'scale-100 opacity-100' : 'scale-95 opacity-50'}`}>
    <h3 className="text-xl font-semibold mb-2">{example.title}</h3>
    <h4 className="text-md text-gray-600 italic mb-4">Post: "{example.postTitle}"</h4>
    <div className="flex-grow overflow-y-auto">
      {example.type === 'filtered' ? (
        <>
          <ChatBubble content={example.postContent} type="post" author={example.author} />
          <div className="flex items-center justify-center my-4">
            <AlertTriangle className="text-yellow-500 mr-2" />
            <span className="text-sm font-semibold">Crestovo.ai Assessment</span>
          </div>
          <ChatBubble content={example.insight} type="ai" author="Crestovo.ai" isAI={true} />
        </>
      ) : (
        <>
          <ChatBubble content={example.comment} type="ai" author={example.author} isAI={true} />
          <ChatBubble content={example.reply} type="user" author={example.replyAuthor} />
          <div className="flex items-center justify-center my-4">
            <CheckCircle className="text-green-500 mr-2" />
            <span className="text-sm font-semibold">Value Analysis</span>
          </div>
          <ChatBubble content={example.insight} type="assessment" author="Crestovo.ai" isAI={true} />
        </>
      )}
    </div>
  </div>
);

const CrestovoShowcase = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveIndex((prev) => (prev + 1) % examples.length);
    }, 12000);
    return () => clearInterval(timer);
  }, []);

  const nextExample = () => setActiveIndex((prev) => (prev + 1) % examples.length);
  const prevExample = () => setActiveIndex((prev) => (prev - 1 + examples.length) % examples.length);

  return (
    <div className="bg-gray-100 py-16">
      <div className="max-w-5xl mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8">Crestovo.ai in Action</h2>
        <div className="flex items-center">
          <button
            onClick={prevExample}
            className="flex-shrink-0 bg-white rounded-full p-2 shadow-md hover:bg-gray-100 transition-colors duration-200"
            aria-label="Previous example"
          >
            <ChevronLeft className="w-6 h-6 text-gray-600" />
          </button>
          <div className="overflow-hidden mx-4 flex-grow">
            <div
              className="flex transition-transform duration-300 ease-in-out"
              style={{ transform: `translateX(-${activeIndex * 100}%)` }}
            >
              {examples.map((example, index) => (
                <div key={index} className="w-full flex-shrink-0 px-4">
                  <ExampleCard example={example} isActive={index === activeIndex} />
                </div>
              ))}
            </div>
          </div>
          <button
            onClick={nextExample}
            className="flex-shrink-0 bg-white rounded-full p-2 shadow-md hover:bg-gray-100 transition-colors duration-200"
            aria-label="Next example"
          >
            <ChevronRight className="w-6 h-6 text-gray-600" />
          </button>
        </div>
        <div className="flex justify-center mt-6">
          {examples.map((_, index) => (
            <button
              key={index}
              onClick={() => setActiveIndex(index)}
              className={`w-3 h-3 rounded-full mx-1 ${index === activeIndex ? 'bg-teal-500' : 'bg-gray-300'}`}
              aria-label={`Go to example ${index + 1}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CrestovoShowcase;
