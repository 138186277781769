import React, { useState } from 'react';
import { Zap, Menu, X, Rocket, User, Coins, Search, Edit, Compass, MessageSquare, LineChart, Info } from 'lucide-react';
import HeroSection from './HeroSection';
import KeyBenefits from './KeyBenefits';
import PricingSection from './PricingSection';
import WaitlistForm from './WaitlistForm';
import Analytics from './Analytics';
import CrestovoShowcase from './Showcase';
import Modal from './Modal';
import useWaitlistSubmission from './hooks/useWaitlistSubmission';
import { Helmet } from 'react-helmet';


const CrestovoLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 200" className="w-48">
    <defs>
      <linearGradient id="grad2" x1="0%" y1="100%" x2="100%" y2="0%">
        <stop offset="0%" style={{stopColor:"#2A9D8F",stopOpacity:1}} />
        <stop offset="100%" style={{stopColor:"#E9C46A",stopOpacity:1}} />
      </linearGradient>
    </defs>
    <path d="M50,150 Q100,50 150,125 T250,75" fill="none" stroke="url(#grad2)" strokeWidth="12"/>
    <circle cx="50" cy="150" r="10" fill="#E76F51"/>
    <circle cx="100" cy="50" r="15" fill="#E76F51"/>
    <circle cx="150" cy="125" r="12" fill="#E76F51"/>
    <circle cx="200" cy="100" r="8" fill="#E76F51"/>
    <circle cx="250" cy="75" r="18" fill="#E76F51"/>
    <text x="50" y="190" fontFamily="Arial, sans-serif" fontSize="40" fontWeight="bold">
      <tspan fill="#264653">crestovo</tspan><tspan fill="#2A9D8F">.ai</tspan>
    </text>
  </svg>
);

const PerfectForItem = ({ icon: Icon, title, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center text-center">
    <div className="bg-teal-100 p-3 rounded-full mb-4">
      <Icon className="w-8 h-8 text-teal-600" />
    </div>
    <h3 className="text-xl font-semibold mb-2 text-gray-800">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const HowItWorksStep = ({ icon: Icon, title, description, isLast }) => (
  <div className="flex items-start mb-8 relative">
    <div className="flex-shrink-0 w-12 h-12 rounded-full bg-teal-500 flex items-center justify-center mr-4 z-10">
      <Icon className="w-6 h-6 text-white" />
    </div>
    <div className="flex-grow">
      <h3 className="text-xl font-semibold mb-2 text-gray-800">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
    {!isLast && (
      <div className="absolute left-6 ml-[-1px] w-0.5 h-full bg-teal-200" style={{ top: '3rem', zIndex: 0 }}></div>
    )}
  </div>
);


const App = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const waitlistState = useWaitlistSubmission();

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="min-h-screen bg-gray-50">
      <Analytics />
      <Helmet>
        <title>Crestovo.ai - Supercharge Your SaaS Growth with AI</title>
        <meta name="description" content="AI-powered community engagement to find your perfect audience and boost organic growth for your SaaS. Discover relevant communities and engage authentically." />
        <meta name="keywords" content="SaaS growth, AI-powered marketing, community engagement, organic growth, social media marketing, customer acquisition" />
        <meta property="og:title" content="Crestovo.ai - Supercharge Your SaaS Growth with AI" />
        <meta property="og:description" content="AI-powered community engagement to find your perfect audience and boost organic growth for your SaaS." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.crestovo.ai" />
        <meta property="og:image" content="https://www.crestovo.ai/og-image.jpg" />
        <link rel="canonical" href="https://www.crestovo.ai" />
      </Helmet>

      <header className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
          <CrestovoLogo />
          <nav className="hidden md:block">
            <ul className="flex space-x-6">
              <li><a href="#features" className="text-gray-600 hover:text-teal-500">Features</a></li>
              <li><a href="#how-it-works" className="text-gray-600 hover:text-teal-500">How It Works</a></li>
              <li><a href="#pricing" className="text-gray-600 hover:text-teal-500">Pricing</a></li>
              <li><button onClick={openModal} className="text-teal-500 font-semibold">Early Access</button></li>
            </ul>
          </nav>
          <button
            className="md:hidden"
            onClick={toggleMenu}
            aria-label={isMenuOpen ? "Close menu" : "Open menu"}
          >
            {isMenuOpen ? <X /> : <Menu />}
          </button>
        </div>
        {isMenuOpen && (
          <nav className="md:hidden">
            <ul className="flex flex-col space-y-2 px-4 py-2">
              <li><a href="#features" className="text-gray-600 hover:text-teal-500">Features</a></li>
              <li><a href="#how-it-works" className="text-gray-600 hover:text-teal-500">How It Works</a></li>
              <li><a href="#pricing" className="text-gray-600 hover:text-teal-500">Pricing</a></li>
              <li><button onClick={openModal} className="text-teal-500 font-semibold">Early Access</button></li>
            </ul>
          </nav>
        )}
      </header>

      <main>
        <HeroSection waitlistState={waitlistState} />
        <KeyBenefits />

        <section className="bg-teal-50 py-12">
          <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <h2 className="text-3xl font-bold mb-4 text-gray-900">
              Join Our Exclusive Early Access Program
            </h2>
            <p className="text-xl mb-6 text-gray-600">
              Be among the first to experience Crestovo.ai and enjoy special perks!
            </p>
            <button
              onClick={openModal}
              className="bg-teal-500 hover:bg-teal-600 text-white font-bold py-3 px-8 rounded-full inline-flex items-center justify-center transition duration-300"
            >
              Learn More <Info className="ml-2 w-5 h-5" />
            </button>
          </div>
        </section>

        <section id="how-it-works" className="bg-white py-20">
          <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
            How It Works
          </h2>
          <div className="relative">
            <HowItWorksStep
              icon={Edit}
              title="1. Input Your SaaS Details"
              description="Tell us about your product, target audience, and goals. Our AI uses this information to tailor its search and engagement strategies."
            />
            <HowItWorksStep
              icon={Compass}
              title="2. AI-Powered Community Search"
              description="Our advanced algorithms scan multiple platforms to identify the most relevant communities and discussions for your SaaS."
            />
            <HowItWorksStep
              icon={MessageSquare}
              title="3. Smart Conversation Tracking"
              description="We continuously monitor these communities, identifying opportunities where your product could provide value to ongoing discussions."
            />
            <HowItWorksStep
              icon={Zap}
              title="4. Engagement Suggestions"
              description="Receive AI-generated, context-appropriate suggestions for engaging in discussions, naturally introducing your solution."
            />
            <HowItWorksStep
              icon={LineChart}
              title="5. Performance Analytics"
              description="Track the impact of your engagements, gain insights, and refine your strategy for optimal growth."
              isLast
            />
          </div>
        </div>
      </section>

      <CrestovoShowcase />

        <section className="bg-white py-20">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
            Perfect For
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
            <PerfectForItem
              icon={Rocket}
              title="Early-stage Startups"
              description="Gain traction and grow your user base from day one"
            />
            <PerfectForItem
              icon={User}
              title="Solo Founders"
              description="Maximize your limited time and resources for marketing"
            />
            <PerfectForItem
              icon={Coins}
              title="Bootstrapped Companies"
              description="Implement cost-effective growth strategies"
            />
            <PerfectForItem
              icon={Search}
              title="SaaS Companies"
              description="Increase organic discovery and user acquisition"
            />
          </div>
          <div className="flex justify-center">
              <WaitlistForm
                className="w-full max-w-md"
                email={waitlistState.email}
                setEmail={waitlistState.setEmail}
                isSubmitted={waitlistState.isSubmitted}
                isLoading={waitlistState.isLoading}
                error={waitlistState.error}
                handleSubmit={waitlistState.handleSubmit}
                snackbar={waitlistState.snackbar}
                setSnackbar={waitlistState.setSnackbar}
              />
            </div>
          </div>
        </section>

        <section className="bg-teal-50 py-20">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <blockquote className="text-2xl italic font-semibold text-center text-gray-800">
              "Crestovo.ai has been a game-changer for our startup. We've connected with highly relevant users and seen a 300% increase in organic signups."
              <footer className="text-lg font-normal mt-4">- Alex Chen, Founder of TechSprint</footer>
            </blockquote>
          </div>
        </section>
        <PricingSection />
      </main>

      <footer className="bg-gray-800 text-white py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            <div>
              <h3 className="text-lg font-semibold mb-4">Product</h3>
              <ul className="space-y-2">
                <li><a href="#features" className="hover:text-teal-300">Features</a></li>
                <li><a href="#pricing" className="hover:text-teal-300">Pricing</a></li>
                <li><a href="#" className="hover:text-teal-300">Case Studies</a></li>
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4">Company</h3>
              <ul className="space-y-2">
                <li><a href="#" className="hover:text-teal-300">About Us</a></li>
                <li><a href="#" className="hover:text-teal-300">Careers</a></li>
                <li><a href="#" className="hover:text-teal-300">Contact</a></li>
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4">Resources</h3>
              <ul className="space-y-2">
                <li><a href="#" className="hover:text-teal-300">Blog</a></li>
                <li><a href="#" className="hover:text-teal-300">Documentation</a></li>
                <li><a href="#" className="hover:text-teal-300">Support</a></li>
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4">Legal</h3>
              <ul className="space-y-2">
                <li><a href="#" className="hover:text-teal-300">Privacy Policy</a></li>
                <li><a href="#" className="hover:text-teal-300">Terms of Service</a></li>
              </ul>
            </div>
          </div>
          <div className="mt-8 pt-8 border-t border-gray-700 text-center">
            <p>&copy; 2024 Crestovo.ai. All rights reserved.</p>
          </div>
        </div>
      </footer>

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <h2 className="text-3xl font-bold mb-6 text-center">Exclusive Early Access Program</h2>
        <p className="text-lg mb-6 text-center">Be among the first to experience AI-driven growth hacking with our Early Access Program!</p>

        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-3 flex items-center">
            <span role="img" aria-label="rocket" className="mr-2">🚀</span> Program Benefits:
          </h3>
          <ul className="list-disc list-inside space-y-2">
            <li>14-day free trial with core features</li>
            <li>Priority access before public launch</li>
            <li>Dedicated onboarding support</li>
            <li>Opportunity to provide feedback and shape the product</li>
            <li>20% discount on your first 3 months of a paid plan after the trial</li>
          </ul>
        </div>

        <p className="font-semibold mb-6">
          <span role="img" aria-label="clock" className="mr-2">⏰</span>
          <span className="font-bold">Limited Availability:</span> This offer is exclusive to our waitlist members and is available on a first-come, first-served basis.
        </p>

        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-3 flex items-center">
            <span role="img" aria-label="lightbulb" className="mr-2">💡</span> How It Works:
          </h3>
          <ol className="list-decimal list-inside space-y-2">
            <li>Join our waitlist</li>
            <li>Receive an invitation to the Early Access Program before public launch</li>
            <li>Enjoy 14 days of free access to Crestovo.ai's core features</li>
            <li>Provide valuable feedback to help us improve</li>
            <li>At the end of your trial, get 20% off your first 3 months on any paid plan</li>
          </ol>
        </div>

        <p className="text-lg mb-6 text-center font-semibold">Don't miss this chance to kickstart your SaaS growth before everyone else!</p>

        <WaitlistForm
          className="w-full max-w-md mx-auto"
          email={waitlistState.email}
          setEmail={waitlistState.setEmail}
          isSubmitted={waitlistState.isSubmitted}
          isLoading={waitlistState.isLoading}
          error={waitlistState.error}
          handleSubmit={waitlistState.handleSubmit}
          snackbar={waitlistState.snackbar}
          setSnackbar={waitlistState.setSnackbar}
        />
      </Modal>
    </div>
  );
};

export default App;
