import React, { useEffect } from 'react';
import { X } from 'lucide-react';

const Snackbar = ({ message, type, show, onClose }) => {
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        onClose();
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [show, onClose]);

  if (!show) return null;

  return (
    <div className="fixed top-4 left-1/2 transform -translate-x-1/2 z-50">
      <div className={`px-4 py-2 rounded-md text-white flex items-center justify-between shadow-lg transition-all duration-500 ease-in-out ${
        type === 'success' ? 'bg-green-500' : type === 'info' ? 'bg-blue-500' : 'bg-red-500'
      }`}>
        <span>{message}</span>
        <button onClick={onClose} className="ml-4 focus:outline-none">
          <X size={18} />
        </button>
      </div>
    </div>
  );
};

export default Snackbar;
