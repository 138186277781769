import React from 'react';
import { ChevronRight } from 'lucide-react';
import Snackbar from './Snackbar';

const WaitlistForm = ({ className = '', email, setEmail, isSubmitted, isLoading, error, handleSubmit, snackbar, setSnackbar }) => {
  if (isSubmitted) {
    return (
      <div className={`bg-teal-100 border border-teal-500 text-teal-700 px-6 py-3 rounded-full text-lg inline-block ${className}`}>
        Thanks for joining our waitlist!
      </div>
    );
  }

  return (
    <>
      <form onSubmit={handleSubmit} className={`flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 ${className}`}>
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="px-4 py-3 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-teal-500"
        />
        <button
          type="submit"
          className={`bg-teal-500 hover:bg-teal-600 text-white font-bold py-3 px-8 rounded-full inline-flex items-center justify-center transition duration-300 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={isLoading}
        >
          {isLoading ? 'Joining...' : 'Join Waitlist'}
          <ChevronRight className="ml-2" />
        </button>
      </form>
      <Snackbar
        message={snackbar.message}
        type={snackbar.type}
        show={snackbar.show}
        onClose={() => setSnackbar({ ...snackbar, show: false })}
      />
    </>
  );
};

export default WaitlistForm;
