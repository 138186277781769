import { useState } from 'react';
import axios from 'axios';

const useWaitlistSubmission = (initialState = { email: '', isSubmitted: false }) => {
  const [email, setEmail] = useState(initialState.email);
  const [isSubmitted, setIsSubmitted] = useState(initialState.isSubmitted);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ show: false, message: '', type: 'success' });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setSnackbar({ show: false, message: '', type: 'success' });

    try {
      await axios.post('https://crestovo-waiting-list.thue-comm.workers.dev/waitlist', { email });
      setIsSubmitted(true);
      setEmail('');
      setSnackbar({ show: true, message: 'Successfully joined the waitlist!', type: 'success' });

    // Add this Google Analytics event tracking
      if (window.gtag) {
        window.gtag('event', 'join_waitlist', {
          'event_category': 'engagement',
          'event_label': email,
        });
      }
    } catch (err) {
      console.error('Error submitting to waitlist:', err);
      if (err.response && err.response.status === 409) {
        setSnackbar({ show: true, message: 'This email is already on the waitlist.', type: 'info' });
      } else {
        setSnackbar({ show: true, message: 'An error occurred. Please try again.', type: 'error' });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return { email, setEmail, isSubmitted, setIsSubmitted, isLoading, error, handleSubmit, snackbar, setSnackbar };
};

export default useWaitlistSubmission;
