import React from 'react';
import { Check, AlertCircle } from 'lucide-react';

const PricingTier = ({ name, price, features, recommended, cta }) => (
  <div className={`bg-white p-6 rounded-lg shadow-md ${recommended ? 'border-2 border-teal-500' : ''}`}>
    {recommended && <p className="text-teal-500 font-semibold mb-2">Recommended</p>}
    <h3 className="text-xl font-bold mb-2 text-gray-800">{name}</h3>
    <p className="text-3xl font-bold mb-4 text-gray-800">
      {price === 0 ? 'Free' : `$${price}`}
      {price !== 0 && <span className="text-base font-normal">/month</span>}
    </p>
    <ul className="mb-6">
      {features.map((feature, index) => (
        <li key={index} className="flex items-center mb-2">
          <Check className="w-5 h-5 text-teal-500 mr-2" />
          <span className="text-gray-600">{feature}</span>
        </li>
      ))}
    </ul>
    <button className={`w-full py-2 px-4 rounded ${recommended ? 'bg-teal-500 text-white' : 'bg-gray-200 text-gray-800'} font-semibold`}>
      {cta}
    </button>
  </div>
);

const PricingSection = () => (
  <section id="pricing" className="bg-white py-20">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
        Simple, Transparent Pricing
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <PricingTier
          name="Free"
          price={0}
          features={[
            "Grow 1 product",
            "10 relevant posts/day/product",
            "Manual engagement",
            "Basic analytics",
            "Email support"
          ]}
          cta="Get Started Free"
        />
        <PricingTier
          name="Starter"
          price={49}
          features={[
            "Grow up to 3 products",
            "30 relevant posts/day/product",
            "50 AI-generated engagements/day",
            "Unused post quota rollover (7 days)",
            "Priority email support"
          ]}
          recommended={true}
          cta="Start Growing Now"
        />
        <PricingTier
          name="Pro"
          price={99}
          features={[
            "Grow unlimited products",
            "100 relevant posts/day/product",
            "Unlimited AI-generated engagements",
            "Advanced content review",
            "Unused post quota rollover (30 days)",
            "Advanced analytics",
            "24/7 priority support"
          ]}
          cta="Unleash Full Potential"
        />
      </div>
      <div className="mt-8 text-center">
        <p className="flex items-center justify-center text-gray-600">
          <AlertCircle className="w-5 h-5 mr-2 text-teal-500" />
          Need more? Contact us for custom enterprise solutions.
        </p>
      </div>
    </div>
  </section>
);

export default PricingSection;
