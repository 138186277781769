import React from 'react';
import { BarChart2, Zap, Users } from 'lucide-react';

const BenefitCard = ({ icon: Icon, title, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-md text-center">
    <Icon className="w-12 h-12 text-teal-500 mx-auto mb-4" />
    <h3 className="text-xl font-semibold mb-2 text-gray-800">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const KeyBenefits = () => (
  <section className="bg-gray-50 py-20">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
        Key Benefits
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <BenefitCard
          icon={BarChart2}
          title="Time-Saving Discovery"
          description="AI-powered scanning of social platforms to identify relevant communities for your SaaS."
        />
        <BenefitCard
          icon={Zap}
          title="Intelligent Monitoring"
          description="Real-time analysis of conversations to alert you to product-relevant opportunities."
        />
        <BenefitCard
          icon={Users}
          title="Authentic Engagement"
          description="Generate contextual, value-added comments that naturally introduce your solution."
        />
      </div>
    </div>
  </section>
);

export default KeyBenefits;
