import React from 'react';
import WaitlistForm from './WaitlistForm';

const HeroSection = ({ waitlistState }) => {
  return (
    <section className="bg-gradient-to-b from-teal-50 to-white py-20">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h1 className="text-5xl md:text-6xl font-bold mb-4 text-gray-900">
          Supercharge Your SaaS Growth
        </h1>
        <p className="text-xl mb-8 text-gray-600">
          AI-powered community engagement to find your perfect audience and boost organic growth.
        </p>
        <div className="flex justify-center">
          <WaitlistForm
            className="w-full max-w-md"
            email={waitlistState.email}
            setEmail={waitlistState.setEmail}
            isSubmitted={waitlistState.isSubmitted}
            isLoading={waitlistState.isLoading}
            error={waitlistState.error}
            handleSubmit={waitlistState.handleSubmit}
            snackbar={waitlistState.snackbar}
            setSnackbar={waitlistState.setSnackbar}
          />
          </div>
        </div>
      </section>
  );
};

export default HeroSection;
